import React from 'react'
import { Experience } from './datas/ExperiencesList'

type ExperienceProps = {
    experiences: Experience[];
}


const Experiences = ({ experiences }: ExperienceProps) => {
    return (
        <div className="cursus mb3">
            <h2 className="h2">Expériences professionelles</h2>
            {experiences.map((expecience) => (
                <div className="grid__row" key={expecience.id}>
                    <div className="grid__item">
                        <p className="grid__date">{expecience.date}</p>
                    </div>
                    <div className="grid__item">
                        <h3 className="grid__title">{expecience.title}</h3>
                        <p className="grid__location">{expecience.location}</p>
                        <p className="grid__text">{expecience.text}</p>
                        <ul className="grid__missions">
                            {expecience.missions && expecience.missions.map((mission) => (
                                <li className="grid__mission" key={mission.id}>
                                    {mission.title}
                                </li>
                            ))}
                            {expecience.skills && expecience.skills.map((skill) => (
                                <span className="skills" key={expecience.id + skill}>
                                    {skill}
                                </span>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default Experiences
