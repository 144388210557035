import React from 'react';
import './FormationsExperiences.css';
import Formations from './Formations';
import Experiences from './Experiences';
import DataFormations from './datas/Formations';
import experiencesList from './datas/ExperiencesList';


const FormationsExperiences = ()  => {
    return (
        <>
            <Formations datas={DataFormations}/>
            <Experiences experiences= {experiencesList} />
        </>
    )
}

export default FormationsExperiences
