import React from 'react'

const Interest = ()  => {
    return (
        <div className="skills">
            <h2 className="h2">Centre d'intérêts</h2>
            <ul>
                <li>Informatique</li>
                <li>Jeux vidéos</li>
                <li>Snowboard</li>
                <li>Wakeboard</li>
            </ul>
        </div>

    )
}

export default Interest
